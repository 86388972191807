$desktop-menu-width: 390px;
$mobile-breakpoint-max: 920px;
$editor-mobile-breakpoint-min: $mobile-breakpoint-max + $desktop-menu-width;

$color-map: (primary: primary, secondary: secondary, accent: accent, dark: dark, light: light, foreground: foreground, muted: muted, success: success, warning: warning, danger: danger, info: info, mutedText: muted-text);

$card-grid-max-width: 720px;

$line-height: 1.5em;

@mixin focus-ring {
  outline: 2px solid var(--color-ring);
  outline-offset: 2px;
}
