@use "src/theme";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  line-height: theme.$line-height;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

[data-grabbing="true"] {
  * {
    cursor: grabbing !important;
  }
}


body {
  font-family: var(--font-body-family), sans-serif;
  color: var(--color-foreground);
  background: var(--color-background);
}

:not(:focus-within) > [data-focused="true"] {
  @include theme.focus-ring;
}

:focus-visible, :focus {
  @include theme.focus-ring;
}

.ring-secondary {
  outline-color: var(--color-secondary);
}


[data-radix-menu-content] {
  background: white;
  @apply shadow border;
  min-width: 160px;
  padding: 8px 0;
  z-index: 100;
}

.heading {
  font-family: var(--font-heading-family), sans-serif;
}

.text-xs, .text-sm, .text-md, .text-lg, .text-xl, .text-2xl, .text-3xl, .text-4xl, .text-5xl, .text-6xl, .text-7xl, .text-8xl, .text-9xl {
  line-height: theme.$line-height;
}

@mixin mobile-or-preview {
  @media (max-width: theme.$mobile-breakpoint-max) {
     & {
       @content;
     }
  }

  [data-device="mobile"] & {
    @content;
  }
}


.slate-h1, h1 {
  @apply m-0 p-0 font-semibold;
  line-height: theme.$line-height;
  font-family: var(--font-heading-family), sans-serif;
  font-size: 2.75rem;

  @include mobile-or-preview {
    font-size: 1.75rem;
  }
}

.slate-h2, h2 {
  @apply m-0 p-0 font-semibold;
  line-height: theme.$line-height;
  font-family: var(--font-heading-family), sans-serif;
  font-size: 2.25rem;

  @include mobile-or-preview {
    font-size: 1.5rem;
  }
}

.slate-h3, h3 {
  @apply m-0 p-0 font-semibold;
  line-height: theme.$line-height;
  font-family: var(--font-heading-family), sans-serif;
  font-size: 1.75rem;

  @include mobile-or-preview {
    font-size: 1.25rem;
  }
}

.slate-h4, h4 {
  @apply m-0 p-0 text-lg font-semibold;
  line-height: theme.$line-height;
  font-family: var(--font-heading-family), sans-serif;
}

.slate-h5, h5 {
  @apply m-0 p-0 text-base font-semibold;
  line-height: theme.$line-height;
  font-family: var(--font-heading-family), sans-serif;
}

.slate-h6, h6 {
  @apply m-0 p-0 text-base font-semibold;
  line-height: theme.$line-height;
  font-family: var(--font-heading-family), sans-serif;
}

.slate-p, [data-static-content] .rich-text > div {
  @apply m-0 p-0 text-base;
  font-size: inherit;
  //min-height: 28px;
  min-height: theme.$line-height;
  line-height: theme.$line-height;
}


.twitter-tweet {
  margin: 0 !important;
}

.rich-text {
  overflow: hidden;;
  padding: 0.5rem;
}

@layer base {
  :root {
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --color-muted: #f5f5f5;
    --color-muted-text: #666;
    --color-muted-light: #f9f9f9;

    --color-border: var(--color-muted);
    --color-input: #fff;
    --color-input-disabled: #f2f2f2;
    --color-input-danger: #ede6e6;
    --color-input-warning: #ede6e6;
    --color-ring: #fff;


    --length-radius-small: 0.5rem;
    --length-radius-large: 2.1rem;
    --length-radius-none: 0;


    --length-radius: var(--length-radius-small);
    --length-padding: 0.25rem;
    --color-input-radius: var(--length-radius);

    --color-dark-foreground: var(--color-light);
    --color-light-foreground: var(--color-dark);


  }

}

::selection {
  background: var(--color-selection);
  color: var(--color-selection-foreground);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


[data-slate-editor="true"] {
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
}


.radius {
  border-radius: var(--length-radius);
}

$placements: (
  top: bottom,
  right: left,
  bottom: top,
  left: right,
  top-start: bottom left,
  top-end: bottom right,
  right-start : top left,
  right-end: top right,
  bottom-start: top left,
  bottom-end: top right,
  left-start: top right,
  left-end: top left
);

@mixin popover-placement($placement-name, $placement-anchor) {
  @keyframes popover-#{$placement-name} {
    0% {
      opacity: 0;
      clip-path: circle(0% at $placement-anchor);
    }
    100% {
      opacity: 1;
      clip-path: circle(100% at $placement-anchor);
    }
  }

  &[data-popover-visible="true"] {
    animation: popover-#{$placement-name} 200ms ease-in-out;
  }
}

@each $placement-name, $placement-anchor in $placements {
  body:not([data-grid-dragging="true"]) [data-popover-placement="#{$placement-name}"] {
    @include popover-placement($placement-name, $placement-anchor);
  }
}

[data-popover-visible="false"] {
  visibility: hidden;
  pointer-events: none;
}

[data-popover-visible="true"] {

}

[data-disable-scroll-snap="true"] {
  scroll-snap-type: none !important;
}

$debug: false;

@if $debug {

  :active {
    box-shadow: 0 0 0 3px cyan;
    border-color: cyan;
    fill: cyan;
    stroke: cyan;
  }

}

@keyframes skeleton {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }

}

[data-skeleton-item] {
  position: relative;
}

[data-loading="true"] {
  [data-skeleton-item] {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, #f5f5f5 25%, #f0f0f0 37%, #f5f5f5 63%);
      z-index: 1;
      animation: skeleton 1.2s infinite linear;
    }
  }
}

b, strong {
  font-weight: bold;
}

hr {
  min-width: 1px;
  min-height: 1px;
  background: var(--color-border);
}

ul {

  > li::marker {

    //color: var(--color-accent);
  }
}

[data-loading="true"] {
  animation: loading 300ms infinite alternate;
  cursor: wait;
}

@keyframes loading {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0.5;
  }

}


.tabler-icon {
  opacity: 0.91;
  min-width: 1.2em;
  min-height: 1.2em;
  width: 1.2em;
  height: 1.2em;
}

.no-focus-ring {
  &:focus-visible, &:focus {
    outline: none;
  }
}

.pointer-events-content {
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}

.list-check {
  list-style-type: '\2713';
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

.old-browser-only {
  visibility: hidden;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .old-browser-only {
    visibility: visible;
  }
}

@supports not (inset: 0) {
  .old-browser-only {
    visibility: visible;
  }
}

svg {
  overflow: visible
}

[data-disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.gm-style iframe + div { border:none!important; }

.gmnoprint.gm-style-cc + div {
  display: none;
}

.gmnoprint {
  pointer-events: all;
  cursor: pointer;
}

// zoom styling
/*.gm-bundled-control-on-bottom {
  > div, > div > div {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  button {
    @apply rounded shadow-lg;

    &:focus {
      z-index: 1;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}*/

// searchbox

.pac-container {
  pointer-events: auto;
  @apply shadow-lg rounded;
}

.pac-container .pac-item {
  z-index: 10;
}



// ---

.old-browser-overlay {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  font-family: sans-serif;
  background: white;
  background: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  @apply p-8;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
